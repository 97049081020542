<template>
    <div class="common_content join_box">
        <div class="cus_tab">
            <div v-for="item in tabs" :key="item.id" class="tab_item" :class="{'active': item.id === isActive}" @click="handleChangeTab(item)">{{item.name}}</div>
        </div>
        <div class="cus_tab_content">
            <ul v-if="isActive===1">
                <li v-for="(news,index) in companyNewList" :key="index" @click="handleToDetail(news)">
                    <div class="c_line  animated fadeInUp"></div>
                    <div class="c_title animated fadeInUp">{{news.title}}</div>
                    <div class="c_box">
                        <p class="animated fadeInUp"><span style="font-weight:bolder">工作类型：</span><span>{{news.extra.type==='1'?'全职':'兼职'}}</span></p>
                        <p class="animated fadeInUp"><span style="font-weight:bolder">工作地点：</span><span>{{news.extra.address}}</span></p>
                        <p class="animated fadeInUp"><span style="font-weight:bolder">发布时间：</span><span>{{news.extra.date_at}}</span></p>
                        <p class="animated fadeInUp"><span style="font-weight:bolder">招聘人数：</span><span>{{news.extra.total}}</span></p>
                    </div>
                    <div class="r_allow">
                        <img src="../../assets/joinUs/join_right_allow_icon.png"/>
                    </div>
                </li>
                <div class="pagination_box">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :current-page="page.currentPage"
                        :page-size="page.pageSize"	
                        :total="page.total">
                    </el-pagination>
                </div>
            </ul>
            <!-- //校园招聘 -->
            <ul v-if="isActive===2">
                <li v-for="(news,index) in companyNewList2" :key="index" @click="handleToDetail(news)">
                    <div class="c_line animated fadeInUp"></div>
                    <div class="c_title animated fadeInUp">{{news.title}}</div>
                    <div class="c_box">
                        <p class="animated fadeInUp"><span style="font-weight:bolder">工作类型：</span><span>{{news.extra.type==='1'?'全职':'兼职'}}</span></p>
                        <p class="animated fadeInUp"><span style="font-weight:bolder">工作地点：</span><span>{{news.extra.address}}</span></p>
                        <p class="animated fadeInUp"><span style="font-weight:bolder">发布时间：</span><span>{{news.extra.date_at}}</span></p>
                        <p class="animated fadeInUp"><span style="font-weight:bolder">招聘人数：</span><span>{{news.extra.total}}</span></p>
                    </div>
                    <div class="r_allow">
                        <img src="../../assets/joinUs/join_right_allow_icon.png"/>
                    </div>
                </li>
                <div class="pagination_box">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange2"
                        :current-page="page2.currentPage"
                        :page-size="page2.pageSize"	
                        :total="page2.total">
                    </el-pagination>
                </div>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            tabs:[
                {
                    name: '社会招聘',
                    id: 1,
                },
                {
                    name: '校园招聘',
                    id: 2,
                },
            ],
            isActive: 1,
            companyNewList: [],
            page:{
                currentPage: 1,
                pageSize: 4,
                total:0,
            },
            companyNewList2: [],
            page2:{
                currentPage: 1,
                pageSize: 4,
                total:0,
            }
        }
    },
    created() {
        this.getCompanyNews();
        this.getCompanyNews2();
    },
    methods:{
        handleChangeTab(tab) {
            this.isActive = tab.id;
        },
         handleCurrentChange(currentPage) {
            this.page.currentPage = currentPage;
            this.getCompanyNews();
        },
         // 获取新闻
        getCompanyNews() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let cond= {
                params:{
                    top_category_id: 2,
                    category_id: 3,
                    is_load_extra: 1,
                    page: this.page.currentPage,
                    per_page: this.page.pageSize
                }
            };
            this.$http.get(this.$apiUrl.common.getNews,cond).then((res) => {
                this.companyNewList = res.paginate.data;
                this.page.total = res.paginate.total;
                loading.close();
            }).catch(()=>{
                loading.close();
            });
        },
         handleCurrentChange2(currentPage) {
            this.page2.currentPage = currentPage;
            this.getCompanyNews2();
        },
         // 获取新闻
        getCompanyNews2() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let cond= {
                params:{
                    top_category_id: 2,
                    category_id: 4,
                    is_load_extra: 1,
                    page: this.page2.currentPage,
                    per_page: this.page2.pageSize
                }
            };
            this.$http.get(this.$apiUrl.common.getNews,cond).then((res) => {
                this.companyNewList2 = res.paginate.data;
                this.page2.total = res.paginate.total;
                // let dom = document.getElementById('c_box')
                // let domli = dom.getElementsByTagName('p')
                // this.$nextTick(()=>{
                //     for (let i = 0; i < domli.length; i++) {
                //         console.log(domli[i]);
                //         domli[i].classList.add('animated');
                //         domli[i].classList.add('fadeInUp');
                //         // let delay = 'delay-' + i + 's';
                //         // domli[i].classList.add(delay);
                //     }
                // })
                loading.close();
            }).catch(()=>{
                loading.close();
            });
        },
        handleToDetail(item) {
            this.$router.push({name: 'detail', query:{id: item.id}})
        },
    }
}
</script>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #ee660e;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #ee660e;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
    color: #ffffff;
}
</style>
<style scoped>
.common_content{
    margin-left: 312px;
    margin-top: 4vh;
}
.join_box{
    margin-right: 156px;
}
.cus_tab{
    display: flex;
    justify-content: center;
    cursor: pointer;
}
.tab_item:first-child{
    margin-right: 60px;
}
.tab_item{
    text-align: center;
width: 347px;
height: 61px;
line-height: 61px;
font-size: 21px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #9B9B9B;
/* margin-top: -16px; */
border-bottom: 1px solid #9B9B9B;
}
@keyframes hv{
    100%{
        transform: translateY(-8px);
    }
}

.tab_item.active{
background: #EE660E;
color: #FFFFFF;
box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.4);
/* margin-top: -8px; */
}
.tab_item.active:hover{
position: relative;
top: 0;
}
.tab_item:not(.active){
position: relative;
top: 8px;
}
.tab_item:hover{
    background: #EE660E;
color: #FFFFFF;
box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.4);
position: relative;
top: 0px;
/* animation: hv .5s; */
transition: all 0.3s ease
}
.cus_tab_content{
    margin-top: 101px;
}
.cus_tab_content ul{
    list-style: none;
}
.cus_tab_content ul li{
    width: 1239px;
    /* height: 169px; */
    background: #FFFFFF;
padding: 40px 0 25px 49px;
position: relative;
    margin: 5px 0;
}
.cus_tab_content ul li:hover{
   box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.2);
}
.cus_tab_content ul li:nth-child(2n-1){
    background: #F6F6F6;
}
.c_line{
    width: 5px;
height: 19px;
background: #EE660E;
    display: inline-block;
}
.c_title{
    font-size: 19px;
font-family: AlibabaPuHuiTiB;
font-weight: bolder;
color: #454545;
    display: inline-block;
    margin-left: 26px;
}
.c_box{
    font-size: 15px;
    margin-top: 25px;
    margin-left: 29px;
    width: 100%
}
.c_box p{
    display: inline-block;
    margin-right: 115px;
    color: #454545;
    margin: 0 115px 11px 0;
    width: 180px;
}
.c_box p span{
    color: #9A9AA1;
}
.r_allow{
        position: absolute;
    right: 50px;
    top: 50%;
}
.pagination_box{
        margin-top: 42px;
}
</style>